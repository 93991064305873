import React from "react"

const extractVideoIdFromSrc = src => {
  if (src.includes("v=")) {
    let videoId = src.split("v=")[1]
    const ampersandPosition = videoId.indexOf("&")

    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition)
    }

    return videoId
  }

  return src.split("/").pop()
}

export default ({ youtubeId, src, title, show }) => {
  src = src || `https://www.youtube.com/embed/${youtubeId}?rel=0`

  if (!src.includes("embed")) {
    src = `https://www.youtube.com/embed/${extractVideoIdFromSrc(src)}?rel=0`
  }

  if (show) {
    src = src + "&autoplay=1"
  }

  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title={title || "Відео будинку"}
        allow="autoplay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={src}
        frameBorder="0"
      />
    </div>
  )
}
