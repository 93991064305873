import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import HouseGallery from "./compnents/gallery"
import HouseMainInfo from "./compnents/main-info"
import HousePriceInfo from "./compnents/price-and-forms/house-price"
import { HouseCard } from "../../components/card"
import styles from "./styles/house.module.scss"
import SubPageHouseInfo from "./sub-page-info"
import HouseActionButtons from "./compnents/price-and-forms/house-action-buttons"

const Index = ({ data, location }) => {
  const house = data.house
  const similarHouses = data.similarHouses.edges
  const houseStatus = house.status && house.status.name
  const thumbnailFluid = house.thumbnail && house.thumbnail.fluid

  const title = `Будинок ${house.area} м2, ${house.city.name}`
  const bathroom = house.bathrooms_number
  const seoDescription = `Ціна ${house.price}$. Загальна площа ${house.area}м2. Ділянка ${house.landArea} соток. ${bathroom} санвузли.`

  return (
    <Layout
      title={`${title}. ${houseStatus}`}
      description={seoDescription}
      thumbnail={thumbnailFluid.src}
      contentClassName="layout-content"
      location={location}
    >
      <h1 className={styles.headerTitle}>
        Будинок {house.area} м2,{" "}
        <Link to={"/" + house.city.slug} title={`Будинки ${house.city.name}`}>
          {house.city.name}
        </Link>
      </h1>

      <div className={styles.allContent}>
        <div className={styles.houseContent}>
          <div className={styles.rightHouseContent}>
            <HouseGallery house={house} />
            <div className={styles.showDesktop}>
              <SubPageHouseInfo house={house} />
            </div>
          </div>
          <div className={styles.leftHouseContent}>
            <div className={styles.showMobile}>
              <HousePriceInfo house={house} />
            </div>
            <HouseMainInfo house={house} />
            <div className={styles.showDesktop}>
              <HousePriceInfo house={house} />
            </div>
            <HouseActionButtons house={house} />
            <div className={styles.showMobile}>
              <SubPageHouseInfo house={house} />
            </div>
          </div>
        </div>

        <div>
          <h3>Схожі будинки:</h3>
          <div className="card-list">
            {similarHouses.map(item => (
              <HouseCard house={item.node} key={item.node.slug} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $similarHouses: [String]) {
    house: contentfulHouse(slug: { eq: $slug }) {
      code
      createdAt
      snippet
      price
      area
      house_area
      kitchen_area
      perron_area
      mansard_area
      terrace_area
      balcony_area
      roomsCount
      floors
      landArea
      promoVideo
      archived
      queue
      bedrooms_number
      offices_number
      bathrooms_number
      wardrobes_number
      technical_room_number
      water
      electricity
      parking
      sewerage_volume
      city {
        name
        slug
        infrastructures {
          name
        }
      }
      status {
        name
      }
      thumbnail {
        fluid(maxWidth: 1200, maxHeight: 900, cropFocus: CENTER) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      externalPhotos {
        fluid(maxWidth: 1000) {
          sizes
          src
          srcSet
        }
      }
      insidePhotos {
        fluid(maxWidth: 1000) {
          sizes
          src
          srcSet
        }
      }
      planningPhotos {
        fluid(maxWidth: 1000) {
          sizes
          src
          srcSet
        }
      }
      description {
        json
      }
      location {
        lon
        lat
      }
      bathrooms_number
    }
    similarHouses: allContentfulHouse(
      filter: { node_locale: { eq: "uk-UA" }, slug: { in: $similarHouses } }
      limit: 4
      sort: { fields: [price], order: ASC }
    ) {
      edges {
        node {
          area
          slug
          code
          snippet
          roomsCount
          hotSale
          landArea
          building
          createdAt
          price
          bathrooms_number
          city {
            name
            region
          }
          thumbnail {
            fluid(maxWidth: 600) {
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default Index
