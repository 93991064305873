import React from "react"

import GoogleMap from "./map"
import Marker from "./marker"

const Map = ({ lat, lng, title, zoom, size }) => (
  <GoogleMap defaultCenter={{ lat, lng }} defaultZoom={zoom || 14}>
    <Marker lat={lat} lng={lng} text={title} size={size} />
  </GoogleMap>
)

export default Map
