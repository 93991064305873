import Img from "gatsby-image"
import React, { useState } from "react"
import ModalPlaningImage from "./photo-modal"
import styles from "./planning.module.scss"

export const PlaningPhoto = ({ house }) => {
  const [showModal, setShowModal] = useState(false)
  const [fluid, setFluid] = useState()

  return house.planningPhotos ? (
    <div>
      {house.planningPhotos.map((photo, i) => (
        <div
          key={i}
          onClick={() => {
            setShowModal(true)
            setFluid(photo.fluid)
          }}
        >
          <Img
            fluid={photo.fluid}
            className={styles.planningPhoto}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      ))}
      <ModalPlaningImage
        title={house.title}
        fluid={fluid}
        show={showModal}
        setShow={setShowModal}
      />
    </div>
  ) : null
}
