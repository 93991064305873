import React from "react"
import styles from "./price.module.scss"
import { formatPrice } from "../../../../utils/format"

const HousePriceInfo = ({ house }) => {
  const houseStatus = house.status && house.status.name
  const priceByArea = Math.round((house.price * 100) / house.area) / 100

  return !house.archived ? (
    <div className={styles.content}>
      <div className={styles.houseCode}>{house.code}</div>
      <div className={styles.priceAndStatus}>
        <div className={styles.price}>
          {house.price ? (
            <>
              <b className={styles.bigPrice}>{formatPrice(house.price)} $</b>
              <br />
              <span className={styles.byAreaPrice}>{priceByArea} $/м²</span>
            </>
          ) : (
            <div className={styles.noPrice}>ціну не вказано</div>
          )}
        </div>

        <div className={styles.status}>{houseStatus}</div>
      </div>
    </div>
  ) : null
}

export default HousePriceInfo
