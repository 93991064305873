import React, { useState } from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import Img from "gatsby-image/index"

import { ModalGallery } from "../../../../components/gallery"

import styles from "./gallery.module.scss"

const HouseGallery = ({ house }) => {
  const title = `Будинок ${house.area} м2, ${house.city.name}`

  const externalPhotos = house.externalPhotos
    ? house.externalPhotos.map(photo => photo.fluid)
    : []
  const insidePhotos = house.insidePhotos
    ? house.insidePhotos.map(photo => photo.fluid)
    : []

  const thumbnail = house.thumbnail.fluid
  const images = [...externalPhotos, ...insidePhotos]

  const getSrcRoot = src => src && src.split("?")[0]
  const isFirstPictureThumbnail =
    getSrcRoot(images[0].src) !== getSrcRoot(thumbnail.src)

  isFirstPictureThumbnail && images.unshift(thumbnail)

  const [showModalGallery, setShowModalGallery] = useState(false)
  const [slide, setSlide] = useState(0)

  const renderFraction = function (currentClass, totalClass) {
    return `
    <div class="${styles.pagination}">
      <div  class="${styles.fraction}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
        <path d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="3.2"/>
        <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
      </svg>
       <span class="${currentClass}"></span>
       <span style="margin: 0 2px;">/</span>
       <span class="${totalClass}"></span>
      </div>
    </div>
`
  }

  return (
    <div className={styles.galleryWrapper}>
      <Swiper
        navigation
        pagination={{ type: "fraction", renderFraction: renderFraction }}
        onSlideChange={e => setSlide(e.realIndex)}
        keyboard={{ enabled: true }}
        scrollbar={{ draggable: true }}
        loop={true}
        lazy={true}
      >
        {images.map((image, i) => (
          <SwiperSlide
            key={image.src + i}
            onClick={() => setShowModalGallery(true)}
          >
            {house.archived && <div className={styles.sold}>Продано</div>}
            <Img
              fluid={image}
              objectFit="cover"
              objectPosition="50% 50%"
              loading="eager"
              className={styles.photo}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <ModalGallery
        title={title}
        images={images}
        show={showModalGallery}
        setShow={setShowModalGallery}
        initialSlide={slide}
        contentType="Будинок"
      />
    </div>
  )
}

export default HouseGallery
