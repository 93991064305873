import React from "react"

import { getIcon } from "../../../utils"
import { Icon } from "../../../components/icon"

import styles from "./marker.module.scss"

const Marker = ({ text, onClick, size }) => (
  <div
    onClick={onClick}
    className={styles.marker}
    style={{ fontSize: size || "32px" }}
    aria-hidden="true"
  >
    <Icon name={text} icon={getIcon("house two")} />
  </div>
)

export default Marker
