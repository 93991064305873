import React from "react"
import Modal from "react-overlays/Modal"
import { getIcon } from "../../../../utils/index"
import { Icon } from "../../../../components/icon"
import styles from "./photo-modal.module.scss"
import Img from "gatsby-image"

function ModalPlaningImage({ title, fluid, show, setShow }) {
  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="modal-label"
      renderBackdrop={renderBackdrop}
      className={styles.modal}
    >
      <div>
        <div className={styles.header}>
          <h3>{title}</h3>
          <button
            onClick={() => setShow(false)}
            type="button"
            aria-label="закрити"
          >
            <Icon name={"закрити"} icon={getIcon("close")} />
            <span> Закрити</span>
          </button>
        </div>
        <div className={styles.galleryWrapper}>
          <Img
            fluid={fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "90vh" }}
            alt={title}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalPlaningImage
