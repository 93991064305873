import React from "react"

import styles from "./rooms.module.scss"

const RoomsInfo = ({ house }) => {
  const items = [
    { label: "Спальні", value: house.bedrooms_number },
    { label: "Кабінети", value: house.offices_number },
    { label: "Санвузли", value: house.bathrooms_number },
    { label: "Гардероби", value: house.wardrobes_number },
    { label: "Технічні кімнати", value: house.technical_room_number },
  ].filter(item => item.value != null)

  return (
    <div className={styles.rooms}>
      {items.map(item => (
        <div className={styles.item} key={item.label}>
          <div className={styles.itemLabel}>
            <span>{item.label}</span>
          </div>
          <div className={styles.itemValue}>
            <b>{item.value}</b>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RoomsInfo
