import React from "react"
import GoogleMap from "../../../../components/google/map"

const HouseMap = ({ house }) => {
  const title = `Будинок ${house.area} м2, ${house.city.name}`

  const haveLocation =
    house.location && house.location.lat && house.location.lon

  return haveLocation ? (
    <div>
      <GoogleMap
        lat={house.location.lat}
        lng={house.location.lon}
        title={title}
        zoom={10}
        size={28}
      />
    </div>
  ) : null
}

export default HouseMap
