import React from "react"
import styles from "./main-info.module.scss"

const InfoItem = ({ value, title }) => {
  return value ? (
    <div className={styles.infoItem}>
      <div className={styles.infoItemTitle}>{title}</div>
      <b>{value}</b>
    </div>
  ) : null
}

const HeaderInfoItem = ({ value, title }) => {
  return (
    <div className={styles.headerInfoItem}>
      <div className={styles.infoItemTitle}>{title}</div>
      <b>{value}</b>
    </div>
  )
}

const HouseMainInfo = ({ house }) => {
  return (
    <div className={styles.infoItemList}>
      <HeaderInfoItem
        value={house.area && `${house.area}  м²`}
        title="Загальна площа"
      />
      <InfoItem
        value={house.house_area && `${house.house_area}  м²`}
        title="Будинок"
      />
      <InfoItem
        value={house.kitchen_area && `${house.kitchen_area}  м²`}
        title="Кухня"
      />
      <InfoItem
        value={house.perron_area && `${house.perron_area}  м²`}
        title="Ганок"
      />
      <InfoItem
        value={house.terrace_area && `${house.terrace_area}  м²`}
        title="Тераса"
      />
      <InfoItem
        value={house.balcony_area && `${house.balcony_area}  м²`}
        title="Балкон"
      />
      <InfoItem
        value={house.mansard_area && `${house.mansard_area}  м²`}
        title="Мансарда"
      />
      <HeaderInfoItem
        value={house.landArea && `${house.landArea} сот.`}
        title="Площа ділянки"
      />
      <InfoItem value={house.water} title="Вода" />
      <InfoItem value={house.electricity} title="Електрика" />
      <InfoItem
        value={house.sewerage_volume && `${house.sewerage_volume} м³`}
        title="Каналізація"
      />
      <InfoItem value={house.parking} title="Парковка" />
    </div>
  )
}

export default HouseMainInfo
