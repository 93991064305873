import React from "react"
import YoutubeVideo from "../../../../components/video/youtube"

const HouseVideo = ({ house }) => {
  const title = `Будинок ${house.area} м2, ${house.city.name}`

  return house.promoVideo ? <YoutubeVideo src={house.promoVideo} title={title} show={false} /> : null
}

export default HouseVideo
