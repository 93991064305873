import React from "react"
import { ExcursionHouseForm } from "../../../../components/form"
import CheckAvailabilityHouseForm from "../../../../components/form/check-availability"

const HouseActionButtons = ({ house }) => {
  const title = `Будинок ${house.area} м2, ${house.city.name}`
  const thumbnailFluid = house.thumbnail && house.thumbnail.fluid

  return !house.archived ? (
    <div style={{ margin: "8px 0" }}>
      <CheckAvailabilityHouseForm
        title={title}
        thumbnail={thumbnailFluid}
        showButtonTitle="Уточнити наявність"
      />
      <ExcursionHouseForm
        title={title}
        thumbnail={thumbnailFluid}
        showButtonTitle="Записатися на перегляд"
      />
    </div>
  ) : null
}

export default HouseActionButtons
