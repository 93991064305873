import React, { useState } from "react"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "./styles/sub-page-info.module.scss"
import HouseMap from "./compnents/map"
import HouseVideo from "./compnents/video"
import RoomsInfo from "./compnents/info/rooms"
import { PlaningPhoto } from "./compnents/planning"

const HouseInfrastructure = ({ house }) => {
  return house.city.infrastructures ? (
    <div className={styles.cityInfrastructure}>
      <Link
        to={`/${house.city.slug}/infrastructures`}
        title={`Інфраструктура ${house.city.name}`}
      >
        Детальніше про інфраструктуру, яка знаходиться поряд
      </Link>
    </div>
  ) : null
}

const HouseAbout = ({ house }) => {
  const description = house.description && house.description.json
  const descriptionOptions = {
    renderNode: {
      "embedded-asset-block": node => {
        const url = node.data.target.fields.file["uk-UA"].url
        const alt = node.data.target.fields.title["uk-UA"]

        return <img src={url} alt={alt} />
      },
    },
  }
  return (
    <div>
      <RoomsInfo house={house} />
      {description ? (
        <div className="description-text">
          {documentToReactComponents(description, descriptionOptions)}
        </div>
      ) : null}
      <HouseInfrastructure house={house} />
    </div>
  )
}

const MenuBtn = ({ title, active, onClick }) => {
  const classes = [styles.menuBtn]

  if (active) {
    classes.push(styles.menuBtnActive)
  }

  return (
    <button className={classes.join(" ")} onClick={onClick}>
      {title}
    </button>
  )
}

const SubPageHouseInfo = ({ house }) => {
  const [activeWindow, setActiveWindow] = useState("aboutHouse")
  const location = house.location && house.location.lat && house.location.lon

  return (
    <div>
      <div className={styles.headerMenu}>
        <MenuBtn
          title="Про будинок"
          active={activeWindow === "aboutHouse"}
          onClick={() => setActiveWindow("aboutHouse")}
        />
        {!!house.planningPhotos && (
          <MenuBtn
            title="Планування"
            active={activeWindow === "planning"}
            onClick={() => setActiveWindow("planning")}
          />
        )}
        {!!house.promoVideo && (
          <MenuBtn
            title="Відеоогляд"
            active={activeWindow === "video"}
            onClick={() => setActiveWindow("video")}
          />
        )}
        {!!location && (
          <MenuBtn
            title="Карта"
            active={activeWindow === "map"}
            onClick={() => setActiveWindow("map")}
          />
        )}
      </div>
      <div>
        {activeWindow === "aboutHouse" && <HouseAbout house={house} />}
        {activeWindow === "planning" && <PlaningPhoto house={house} />}
        {activeWindow === "video" && <HouseVideo house={house} />}
        {activeWindow === "map" && <HouseMap house={house} />}
      </div>
    </div>
  )
}

export default SubPageHouseInfo
